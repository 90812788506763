import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";
import { useLoginMutation, useRegisterMutation } from "./authApiSlice";
import useTitle from "../../hooks/useTitle";
import PulseLoader from "react-spinners/PulseLoader";

import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";

const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

const LoginMobil = () => {
  useTitle("DepenDent.RO");

  const userRef = useRef();
  const errRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpass, setConfirmpass] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);
  const [errConfirmPass, setErrConfirmPass] = useState(true);
  const [errEmail, setErrEmail] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();

  const [register] = useRegisterMutation();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  useEffect(() => {
    setErrEmail(true);
  }, [email]);

  useEffect(() => {
    setErrConfirmPass(true);
  }, [confirmpass]);

  // Reset Form
  const resetForm = () => {
    setEmail("");
    setPassword("");
    setConfirmpass("");
    setErrConfirmPass(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSignUp) {
      /// inregistrare
      if (EMAIL_REGEX.test(email)) {
        try {
          if (password !== confirmpass) {
            setErrConfirmPass(false);
          } else if (
            !(
              password.length >= 8 &&
              /[a-z]/.test(password) &&
              /[A-Z]/.test(password) &&
              /\d/.test(password)
            )
          ) {
            setErrMsg("Parola nu respecta cerintele");
          } else {
            await register({
              email: email,
              password: password,
            }).unwrap();

            setIsSignUp((prev) => !prev);
          }
        } catch (err) {
          if (!err.status) {
            setErrMsg("Serverul nu raspunde");
          } else if (err.status === 400) {
            setErrMsg("Va rugam sa introduceti o parola");
          } else if (err.status === 401) {
            setErrMsg("Acces neautorizat");
          } else {
            setErrMsg(err.data?.message);
          }
          errRef.current.focus();
        }
      } else {
        setErrEmail(false);
      }
    } else {
      //// logare
      if (EMAIL_REGEX.test(email)) {
        try {
          const { accessToken } = await login({ email, password }).unwrap();

          dispatch(setCredentials({ accessToken }));
          setEmail("");
          setPassword("");
          navigate("/");
        } catch (err) {
          if (!err.status) {
            setErrMsg("Serverul nu raspunde");
          } else if (err.status === 400) {
            setErrMsg("Va rugam sa introduceti o parola");
          } else if (err.status === 401) {
            setErrMsg("Acces neautorizat");
          } else {
            setErrMsg(err.data?.message);
          }
          errRef.current.focus();
        }
      } else {
        setErrEmail(false);
      }
    }
  };

  const handleUserInput = (e) => setEmail(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleConfPwdInput = (e) => setConfirmpass(e.target.value);

  // const errClass = errMsg ? "errmsg" : "offscreen";
  //console.log(errClass); //

  if (isLoading) return <PulseLoader color={"#FFF"} />;

  const content = (
    <div className="Auth" style={{ flexDirection: "column" }}>
      {/* left side */}

      <div>
        <div className="Webname">
          <h1>DepenDent.Ro</h1>
        </div>
      </div>

      {/* right form side */}

      <div className="a-right">
        <form className="infoForm authForm" onSubmit={handleSubmit}>
          <h3>{isSignUp ? "Inregistrare" : "Autentificare"}</h3>

          <div>
            <Input
              required
              type="text"
              placeholder="Email-ul dvs."
              name="email"
              ref={userRef}
              value={email}
              onChange={handleUserInput}
              autoComplete="off"
            />
          </div>
          <span
            style={{
              color: "red",
              fontSize: "12px",
              alignSelf: "flex-end",
              marginRight: "5px",
              display: errEmail ? "none" : "block",
              marginTop: "-1rem",
            }}
          >
            *Email-ul introdus nu este valid
          </span>

          <div>
            <Input.Password
              required
              placeholder="Parola"
              name="password"
              value={password}
              onChange={handlePwdInput}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
            {isSignUp && (
              <Input.Password
                required
                name="confirmpass"
                placeholder="Confirmare parola"
                value={confirmpass}
                onChange={handleConfPwdInput}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            )}
          </div>
          <span
            style={{
              color: "red",
              fontSize: "12px",
              alignSelf: "flex-end",
              marginRight: "5px",
              display: errConfirmPass ? "none" : "block",
              marginTop: "-1rem",
            }}
          >
            *Parola de confirmare nu este identica
          </span>

          {isSignUp && (
            <>
              <div style={{ fontSize: "1rem" }}>
                Parola trebuie sa indeplineasca urmatoarele caracteristici:
              </div>

              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  gap: "5px",
                  height: "100px",
                  width: "90%",
                  marginTop: "-1rem",
                }}
              >
                <li
                  style={{
                    fontSize: "15px",
                    color: password.length >= 8 ? "green" : "grey",
                  }}
                >
                  Sa contina minim 8 caractere
                </li>
                <li
                  style={{
                    fontSize: "15px",
                    color: /[a-z]/.test(password) ? "green" : "grey",
                  }}
                >
                  Sa contina minim o litera mica
                </li>
                <li
                  style={{
                    fontSize: "15px",
                    color: /[A-Z]/.test(password) ? "green" : "grey",
                  }}
                >
                  Sa contina minim o litera mare
                </li>
                <li
                  style={{
                    fontSize: "15px",
                    color: /\d/.test(password) ? "green" : "grey",
                  }}
                >
                  Sa contina minim un numar
                </li>
              </ul>
            </>
          )}

          {errMsg && (
            <div
              style={{
                color: "red",
                fontSize: "1rem",
              }}
            >
              {errMsg}
            </div>
          )}

          <div>
            <span
              style={{
                fontSize: "12px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                resetForm();
                setIsSignUp((prev) => !prev);
              }}
            >
              {isSignUp ? "Ai deja un cont" : "Nu ai un cont Înscrie-te"}
            </span>
            <button className="button infoButton" type="Submit">
              {isSignUp ? "Inregistrare" : "Conectare"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return content;
};
export default LoginMobil;
