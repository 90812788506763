import React, { useState, useEffect } from "react";
import { useGetCatalogQuery } from "../features/catalog/catalogApiSlice";
import { useGetBatchSubscribeQuery } from "../features/plati/subscribeApiSlice";
import useAuth from "../hooks/useAuth";
import { skipToken } from "@reduxjs/toolkit/query/react";
import CardAbonament from "../features/user/CardAbonament";
import UsersList from "../features/user/UsersList";

function Setari() {
  const { id } = useAuth();
  const [subscribes, setSubscribes] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const { data: catalog } = useGetCatalogQuery();

  const { data: subscribe, isSuccess } = useGetBatchSubscribeQuery(
    categoryList.length > 0 ? { id: id, categoryList } : skipToken
  );

  useEffect(() => {
    if (catalog) {
      const newCategoryList = catalog.map((item) => item._id); // Creează o listă nouă cu id-urile
      setCategoryList(newCategoryList); // Actualizează state-ul cu lista generată
    }
  }, [catalog]);

  useEffect(() => {
    if (categoryList.length > 0) {
      setSubscribes(subscribe); // Setează starea o singură dată
    }
  }, [categoryList, isSuccess]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <div
          className="user-container"
          style={{
            backgroundColor: "rgb(31, 31, 31)",
            // width: "50%",
            borderRadius: "8px",
          }}
        >
          <h2 style={{ padding: "1rem" }}>Cont</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UsersList />
          </div>
        </div>

        <div
          className="user-container"
          style={{
            backgroundColor: "rgb(31, 31, 31)",
            // width: "50%",
            borderRadius: "8px",
          }}
        >
          <h2 style={{ padding: "1rem" }}>Abonamente Active</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {!subscribes ||
              (subscribes.length === 0 && (
                <h3 style={{ marginBottom: "1rem" }}>
                  Nu este niciun abonament activat!
                </h3>
              ))}

            {subscribes?.map((item, i) => {
              if (item.expireSub !== false) {
                return <CardAbonament subs={item} key={i} />;
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Setari;
