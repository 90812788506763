import React, { useState } from "react";
import { useGetCatalogQuery } from "../catalog/catalogApiSlice";
import Card from "../catalog/card/Card";
import { apiUrl } from "../../app/url";
import Subscribe from "./Subscribe";

function Abonamente() {
  const [product, setProduct] = useState({});
  const [open, setOpen] = useState(false);

  const { data: catalog, isLoading, isSuccess, error } = useGetCatalogQuery();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        marginTop: "1.5rem",
      }}
    >
      {isSuccess ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              padding: "5px",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            {catalog?.map((item, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    setProduct(item);
                    setOpen(true);
                  }}
                >
                  <Card catalog={item} apiUrl={apiUrl} />
                </div>
              );
            })}
          </div>
        </>
      ) : isLoading ? (
        <p>Se incarca date...</p>
      ) : (
        <p>{error?.data.message}</p>
      )}

      <Subscribe productAb={product} open={open} setOpen={setOpen} />
    </div>
  );
}

export default Abonamente;
