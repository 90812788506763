import React from "react";
import LoginDesktop from "../features/auth/LoginDesktop";
import LoginMobil from "../features/auth/LoginMobil";
import { BrowserView, MobileView } from "react-device-detect";

function Player() {
  return (
    <>
      <BrowserView>
        <LoginDesktop />
      </BrowserView>
      <MobileView>
        <LoginMobil />
      </MobileView>
    </>
  );
}

export default Player;
