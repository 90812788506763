import React, { useEffect, useState } from "react";
import { useGetSongQuery } from "./songsApiSlice";
import { useDispatch } from "react-redux";
import { apiUrl } from "../../app/url";
import useAuth from "../../hooks/useAuth";
import {
  useGetUserQuery,
  useUpdateDateStartMutation,
} from "../user/usersApiSlice";
import { songListUpdated } from "../player/actions";
import { UnlockFilled } from "@ant-design/icons";
import { formatReadableDate } from "../../hooks/date";
import SongsList from "./SongsList";

function CheckWhenStart({ category }) {
  const { id } = useAuth();
  const dispatch = useDispatch();
  const [categoryData, setCategoryData] = useState();

  const [categoryToStart, setCategoryToStart] = useState(false);
  const [dateToStart, setDateToStart] = useState(false);
  const [playingProgress, setPlayingProgress] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [updateDateStart] = useUpdateDateStartMutation();

  const {
    data: song,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSongQuery(id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { data: user, refetch } = useGetUserQuery(id);

  const getUser = async () => {
    if (user?.user) {
      setPlayingProgress(user.user.playingProgress);
    }
  };

  useEffect(() => {
    const progress = playingProgress.find(
      (progress) => progress.categorieID.toString() === category._id
    );

    setDateToStart(progress?.startedAt);

    if (categoryData) {
      if (progress) {
        setSelectedCategory(category.categorie);
        dispatch(songListUpdated(categoryData[category.categorie]));
        setCategoryToStart(null);
      } else {
        setSelectedCategory(null);
        setCategoryToStart(category.categorie);
      }
    }
  }, [category, categoryData, playingProgress]);

  useEffect(() => {
    const groupedData = song?.reduce((acc, item) => {
      const url = item.audioUrl.split("/");
      const poza = item.profilePicture;

      if (!acc[item.categorie]) {
        acc[item.categorie] = [];
      }
      acc[item.categorie].push({
        uuid: item._id,
        categorieID: item.categorieID,
        numeCategorie: item.categorie,
        name: item.numeHipnoza,
        unlockPeriod: item.unlockPeriod,
        src: `${apiUrl}/audio/v2/${url[1].slice(0, -4)}`,
        img: `${apiUrl}/${poza}`,
      });
      return acc;
    }, {});

    setCategoryData(groupedData);
    getUser();
  }, [song]);

  const handleSetDate = async () => {
    await updateDateStart({
      id: id,
      categoryId: category._id,
    });

    const refreshedData = await refetch();

    if (refreshedData?.data?.user) {
      setPlayingProgress(refreshedData.data.user.playingProgress);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {selectedCategory && (
        <div style={{ marginTop: "0.75rem" }}>
          <h2>{selectedCategory}</h2>
          <div>
            Program inceput in data de: {formatReadableDate(dateToStart)}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <SongsList dateToStart={dateToStart} />
          </div>
        </div>
      )}

      {categoryToStart && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "17rem",
            flexDirection: "column",
            marginTop: "5rem",
          }}
        >
          <div> Acest plan dureaza 6 saptamani</div>
          <div> Pentru a incepe apasa butonul de mai jos</div>
          <div
            style={{
              color: "black",
              padding: "10px",
              border: "1px solid black",
              borderRadius: "5px",
              cursor: "pointer",
              backgroundColor: "#fff",
            }}
            onClick={() => handleSetDate()}
          >
            <UnlockFilled /> Incepe programul
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckWhenStart;
