import React from "react";
import "./Card.css";

const Card = ({ catalog, apiUrl }) => {
  return (
    <div
      className="spotify-card"
      style={{
        userSelect: "none",
      }}
    >
      <div className="album-cover">
        <img
          style={{ width: 120, height: 120 }}
          src={`${apiUrl}/${catalog.profilePicture}`}
          alt={`${catalog.categorie} Album Cover`}
        />
      </div>
      <div className="song-info">
        <h3 className="song-title">{catalog.categorie}</h3>
        <p className="artist-name">erterter</p>
        <p className="artist-name">Pret: {catalog.pret} lei/luna</p>
      </div>
    </div>
  );
};

export default Card;
