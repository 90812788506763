import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPlata } from "../features/plati/api";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function Confirm() {
  const token = useSelector((state) => state.auth.token);
  const [orderId, setOrderId] = useState(false);
  const [order, setOrder] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const location = useLocation(); // Obține informațiile despre locația curentă

  async function fetchDataHipnoze(orderID) {
    const orderFiles = await getPlata(orderID, config);
    setOrder(orderFiles);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Oprește pagina de încărcare după 5 secunde
      const queryParams = new URLSearchParams(location.search); // Creează un obiect pentru a accesa parametrii query
      const orderId = queryParams.get("orderId"); // Extrage valoarea parametrului "orderId"
      setOrderId(orderId);
      fetchDataHipnoze(orderId);
    }, 5000);

    // Curățarea temporizatorului la schimbarea componentelor sau la demontare
    return () => clearTimeout(timer);
  }, []);
  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {loading ? (
        // Afișează pagina de încărcare
        <div className="loading">
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 48,
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        // Conținutul care apare după 5 secunde
        <>
          {order.data ? (
            <>
              <h3>Comanda #{orderId} este confirmata!</h3>

              <div>
                Abonamentul pentru categoria {order?.data.categorieName} este
                activ din acest moment!
              </div>

              <div>Felicitari pentru abonamentul achizitionat!</div>

              <Button onClick={() => navigate("/")}>
                Inapoi la pagina principala
              </Button>
            </>
          ) : (
            <>
              <div>Comanda #{orderId} nu s-a finalizat cu succes!</div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Confirm;
