import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSendLogoutMutation } from "../features/auth/authApiSlice";
import useAuth from "../hooks/useAuth";
import {
  CaretRightOutlined,
  PoweroffOutlined,
  SettingOutlined,
  BlockOutlined,
} from "@ant-design/icons";

const DropdownMenu = () => {
  const { email } = useAuth();
  const navigate = useNavigate();

  const firstLetter = email.charAt(0);

  const [sendLogout] = useSendLogoutMutation();
  // State pentru a controla vizibilitatea meniului
  const [isOpen, setIsOpen] = useState(false);

  // Ref pentru componenta meniului
  const menuRef = useRef(null);

  // Functia pentru a schimba vizibilitatea meniului
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Functia care închide meniul când se face click în afara lui
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Adaugă/Elimină event listener-ul pentru click în afara meniului
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener-ul atunci când componenta este demontată
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div
      style={{ position: "relative", display: "inline-block" }}
      ref={menuRef}
    >
      {/* Div-ul rotund care acționează ca un buton */}
      <button
        onClick={toggleMenu}
        style={{
          width: "2.2rem",
          height: "2.2rem",
          backgroundColor: "white",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          boxShadow: "0 0 10px 5px white",
          color: "black",
          border: "none",
        }}
      >
        {/* Icon pentru meniu */}
        {firstLetter.toUpperCase()}
      </button>

      {/* Meniul dropdown */}
      {isOpen && (
        <div
          style={{
            position: "absolute",
            width: "200px",
            top: "2.9rem",
            right: "0",
            color: "white",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
            padding: "0.5rem",
            zIndex: 1,
          }}
        >
          <div
            onClick={() => {
              toggleMenu();
              navigate("/");
            }}
            style={{
              display: "flex",
              alignItems: "center",
              height: "40px",
              paddingLeft: "0.5rem",
              marginBottom: "0.5rem",
              textDecoration: "none",
              border: "0.5px solid white",
              borderRadius: "20px",
              backgroundColor: "rgb(18, 18, 18)",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            <CaretRightOutlined /> &nbsp; Player
          </div>

          <div
            onClick={() => {
              toggleMenu();
              navigate("/abonare");
            }}
            style={{
              display: "flex",
              alignItems: "center",
              height: "40px",
              paddingLeft: "0.5rem",
              marginBottom: "0.5rem",
              textDecoration: "none",
              border: "0.5px solid white",
              borderRadius: "20px",
              backgroundColor: "rgb(18, 18, 18)",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            <BlockOutlined /> &nbsp; Abonament
          </div>

          <div
            onClick={() => {
              toggleMenu();
              navigate("/user");
            }}
            style={{
              display: "flex",
              alignItems: "center",
              height: "40px",
              paddingLeft: "0.5rem",
              marginBottom: "0.5rem",
              textDecoration: "none",
              border: "0.5px solid white",
              borderRadius: "20px",
              backgroundColor: "rgb(18, 18, 18)",
              cursor: "pointer",
              userSelect: "none",
            }}
          >
            <SettingOutlined /> &nbsp; Setari cont
          </div>
          <div
            onClick={() => sendLogout()}
            style={{
              display: "flex",
              alignItems: "center",
              height: "40px",
              paddingLeft: "0.5rem",
              marginBottom: "0.5rem",
              textDecoration: "none",
              border: "0.5px solid white",
              borderRadius: "20px",
              backgroundColor: "rgb(18, 18, 18)",
              cursor: "pointer",
              color: "red",
              userSelect: "none",
            }}
          >
            <PoweroffOutlined /> &nbsp; Deconectare
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
