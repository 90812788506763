import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextSong, playOrPause, prevSong } from "./actions";
import { AudioPlayerContext } from "../../components/DefaultLayout";
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
  SoundOutlined,
  MutedOutlined,
} from "@ant-design/icons";
import "./Slider.css";

function formatMusicTime(val) {
  if (val < 10) return `0${val}`;
  return val;
}

function clampValue(value) {
  if (value < 0) {
    return 0;
  } else if (value > 100) {
    return 100;
  } else {
    return value;
  }
}

function AudioPlayer() {
  const isPlaying = useSelector((state) => state.player.isPlaying);
  const currentSong = useSelector((state) => state.player.currentSong);
  const songs = useSelector((state) => state.player.songs);

  const dispatch = useDispatch();
  const { audioPlayer } = useContext(AudioPlayerContext);
  const [volume, setVolume] = useState(localStorage.getItem("volum") || 50);
  const [seekAudio, setSeekAudio] = useState(1);
  const [isMute, setIsMute] = useState({
    muted: false,
    valueBeforeMute: volume,
  });
  const [currentTime, setCurrentTime] = useState("--:--");
  const [currentDuration, setCurrentDuration] = useState("--:--");

  function playStatus() {
    dispatch(playOrPause());
  }

  console.log(seekAudio);

  const handleKeyPress = (event) => {
    switch (event.code) {
      case "Space":
        dispatch(playOrPause());
        break;
      case "ArrowUp":
        const storageVolumePlus = localStorage.getItem("volum") || 50;
        const nextVolumePlus = clampValue(Number(storageVolumePlus) + 5);
        setVolume(nextVolumePlus);
        localStorage.setItem("volum", nextVolumePlus);
        break;
      case "ArrowDown":
        const storageVolume = localStorage.getItem("volum") || 50;
        const nextVolume = clampValue(Number(storageVolume) - 5);
        setVolume(nextVolume);
        localStorage.setItem("volum", nextVolume);
        break;
      case "ArrowLeft":
        const nextSecMinus = localStorage.getItem("seekAudio");
        const nextSecMinus2 = Number(nextSecMinus) - 2;
        changeSeekArrow(nextSecMinus2);
        break;
      case "ArrowRight":
        const nextSecPlus = localStorage.getItem("seekAudio");
        const nextSecPlus2 = Number(nextSecPlus) + 2;
        changeSeekArrow(nextSecPlus2);
        break;
      default:
        // Alte taste pot fi ignorate
        break;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    // Cleanup pentru a elimina event listener-ul
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  async function nextAudio() {
    dispatch(nextSong());
    await audioPlayer.current.pause();
    await audioPlayer.current.load();
    if (isPlaying) audioPlayer.current.play();
  }

  async function prevAudio() {
    dispatch(prevSong());
    await audioPlayer.current.pause();
    await audioPlayer.current.load();
    if (isPlaying) audioPlayer.current.play();
  }

  function changeSeek(e) {
    setSeekAudio(e.target.value);
    audioPlayer.current.currentTime =
      audioPlayer.current.duration * (e.target.value / 100);
  }

  function changeSeekArrow(value) {
    setSeekAudio(value);
    audioPlayer.current.currentTime =
      audioPlayer.current.duration * (value / 100);
  }

  function updateSeek() {
    const musicCurrentTime = audioPlayer.current.currentTime;
    const musicDuration = isNaN(audioPlayer.current.duration)
      ? 100
      : audioPlayer.current.duration; //bug fix for audioPlayer duration
    setSeekAudio(musicCurrentTime * (100 / musicDuration));
    localStorage.setItem("seekAudio", musicCurrentTime * (100 / musicDuration));

    //To update time
    const currentMinutes = formatMusicTime(Math.floor(musicCurrentTime / 60));
    const currentSeconds = formatMusicTime(
      Math.floor(musicCurrentTime - currentMinutes * 60)
    );
    setCurrentTime(`${currentMinutes}:${currentSeconds}`);

    const durationMinutes = formatMusicTime(
      isNaN(audioPlayer.current.duration)
        ? 0
        : Math.floor(audioPlayer.current.duration / 60)
    );
    const durationSeconds = formatMusicTime(
      isNaN(audioPlayer.current.duration)
        ? 0
        : Math.floor(audioPlayer.current.duration - durationMinutes * 60)
    );
    setCurrentDuration(`${durationMinutes}:${durationSeconds}`);

    if (musicDuration - musicCurrentTime === 0) {
      const lastUuid = songs.length > 0 ? songs[songs.length - 1].uuid : null;
      if (lastUuid !== currentSong.uuid) {
        nextAudio();
      }
    }
  }

  function updatePlay() {
    if (!isPlaying) {
      dispatch(playOrPause());
    }
  }
  function updatePause() {
    if (isPlaying) {
      dispatch(playOrPause());
    }
  }

  useEffect(() => {
    audioPlayer.current.volume = volume / 100;
  }, [volume]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isPlaying) audioPlayer.current.play();
    if (!isPlaying) audioPlayer.current.pause();
  }, [isPlaying]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "90dvw",
          flexWrap: "wrap",
          // flexDirection: "row",
          justifyContent: "space-between",
          gap: "0.5rem",
        }}
      >
        {/* poze */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {currentSong.length !== 0 && (
            <>
              <img
                src={currentSong.img}
                style={{ width: "70px", height: "70px" }}
                alt="song_img"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="scrolling-text">
                  <h3>{currentSong.name}</h3>
                </div>
                {/* <h3>{currentSong.name}</h3> */}
              </div>
            </>
          )}
        </div>

        {/* comenzi */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "15px",
              fontSize: "1.5rem",
            }}
          >
            <StepBackwardOutlined
              onClick={currentSong.length !== 0 ? prevAudio : null}
              style={{
                color: currentSong.length !== 0 ? "white" : "gray",
                cursor: currentSong.length !== 0 ? "pointer" : "not-allowed",
              }}
            />
            {!isPlaying ? (
              <PlayCircleOutlined
                onClick={currentSong.length !== 0 ? playStatus : null}
                style={{
                  color: currentSong.length !== 0 ? "white" : "gray",
                  cursor: currentSong.length !== 0 ? "pointer" : "not-allowed",
                }}
              />
            ) : (
              <PauseCircleOutlined
                onClick={currentSong.length !== 0 ? playStatus : null}
                style={{
                  color: currentSong.length !== 0 ? "white" : "gray",
                  cursor: currentSong.length !== 0 ? "pointer" : "not-allowed",
                }}
              />
            )}

            <StepForwardOutlined
              onClick={currentSong.length !== 0 ? nextAudio : null}
              style={{
                color: currentSong.length !== 0 ? "white" : "gray",
                cursor: currentSong.length !== 0 ? "pointer" : "not-allowed",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <span>{currentTime}</span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "300px",
              }}
            >
              <input
                type="range"
                min="1"
                max="100"
                value={seekAudio}
                onChange={currentSong.length !== 0 ? changeSeek : null}
                disabled={currentSong.length === 0}
                className="slider"
                style={{
                  background: `linear-gradient(to right, ${
                    currentSong.length !== 0 ? "#ffffff" : "#cccccc"
                  } ${seekAudio}%, #333333 ${seekAudio}%)`,
                  cursor: currentSong.length !== 0 ? "pointer" : "not-allowed",
                  opacity: currentSong.length !== 0 ? 1 : 0.5,
                }}
              />
            </div>
            <span>{currentDuration}</span>
            <audio
              ref={audioPlayer}
              src={currentSong.src}
              onTimeUpdate={updateSeek}
              onPlay={updatePlay}
              onPause={updatePause}
              muted={isMute.muted}
            >
              <track kind="captions" />
            </audio>
          </div>
        </div>

        {/* sunte */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "7px",
          }}
        >
          {/* !isMute.muted */}
          {volume !== 0 ? (
            <SoundOutlined
              onClick={() => {
                setIsMute({
                  muted: true,
                  valueBeforeMute: volume,
                });
                setVolume(0);
              }}
            />
          ) : (
            <MutedOutlined
              onClick={() => {
                setIsMute({
                  valueBeforeMute: volume,
                  muted: false,
                });
                setVolume(isMute.valueBeforeMute);
              }}
            />
          )}

          <input
            type="range"
            min="0"
            max="100"
            value={volume}
            onChange={(e) => {
              setVolume(e.target.value);
              localStorage.setItem("volum", e.target.value);
            }}
            className="slider"
            style={{
              background: `linear-gradient(to right, ${
                volume > 0 ? "#ffffff" : "#ffffff"
              } ${volume}%, #333333 ${volume}%)`,
            }}
          />

          {/* <button onClick={() => setShowList(true)}>
            <i className="fa-solid fa-list-ul"></i>
          </button> */}
        </div>
      </div>
    </>
  );
}
export default AudioPlayer;
