import React, { useState } from "react";
import { dataTime } from "../../hooks/date";
import card from "../../resourses/MASTERCARD.png";
import { ContainerOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import Factura from "./Factura";

function isEven(value) {
  if (value % 2 == 0) return true;
  else return false;
}

const tableHeaders = [
  "Data",
  "Descriere",
  "Metodă de plată",
  "Factură",
  "Total",
];

function ListaFacturi({ plati }) {
  const [printVisibility, setPrintVisibilty] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: 690 }}>
        <div style={{ marginBottom: "0.5rem", marginTop: "1.2rem" }}>
          Istoricul plăților
        </div>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "10px",
          }}
        >
          <thead>
            <tr
              style={{
                borderBottom: "1px solid #ddd",
              }}
            >
              {tableHeaders.map((header, index) => (
                <th
                  key={index}
                  style={{
                    padding: "10px",
                    textAlign:
                      index === tableHeaders.length - 1 ? "right" : "left",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {plati.map((item, index) => {
              const tva = 1.19;
              const priceWithoutTva = (item.pret / tva).toFixed(2);
              return (
                <tr
                  key={index}
                  style={{
                    borderBottom: "1px solid #ddd",
                    backgroundColor: isEven(index) ? "#363535" : "",
                  }}
                >
                  <td style={{ padding: "6px" }}>
                    {dataTime(item.subscribeData)}
                  </td>
                  <td style={{ padding: "6px", width: 250 }}>
                    {item.descriere}
                  </td>
                  <td
                    style={{
                      padding: "6px",
                      width: 132,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <img
                      src={card}
                      alt="card"
                      style={{ transform: "scale(0.5)" }}
                    />
                    <div>**** **** **** 1234</div>
                  </td>
                  <td style={{ padding: "6px", width: 100, cursor: "pointer" }}>
                    <div
                      onClick={() => {
                        setPrintVisibilty(item);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <ContainerOutlined /> Vizualizare
                    </div>
                  </td>
                  <td
                    style={{ padding: "6px", textAlign: "right", width: 117 }}
                  >
                    <div
                      style={{
                        fontSize: "0.8125rem",
                        fontWeight: "500",
                      }}
                    >
                      {item.pret} LEI
                    </div>
                    <div
                      style={{
                        fontSize: "0.625rem",
                        color: "rgba(255, 255, 255, 0.6)",
                      }}
                    >
                      {priceWithoutTva} LEI (+
                      {(item.pret - priceWithoutTva).toFixed(2)} LEI TVA)
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div style={{ marginTop: "0.5rem" }}>
          Se afișează istoricul de facturare pentru o perioadă de până la un an
        </div>
      </div>

      <Modal
        onCancel={() => {
          setPrintVisibilty(false);
        }}
        open={printVisibility}
        footer={false}
        width={840}
      >
        <Factura plata={printVisibility} />
      </Modal>
    </div>
  );
}

export default ListaFacturi;
