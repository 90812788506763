import React from "react";
import useAuth from "../../hooks/useAuth";
import FirstLogin from "../../features/auth/FirstLogin";
import Songs from "../../pages/Songs";

function Homepage() {
  const { email, chestionar, id, active } = useAuth();

  return (
    <>
      {chestionar === false && (
        <FirstLogin email={email} id={id} active={active} />
      )}
      <Songs />
    </>
  );
}

export default Homepage;
