import React from "react";
import AudioPlayer from "../features/player/AudioPlayer";
import AudioPlayerMobil from "../features/player/AudioPlayerMobil";
import { BrowserView, MobileView } from "react-device-detect";

function Player() {
  return (
    <>
      <BrowserView>
        <AudioPlayer />
      </BrowserView>
      <MobileView>
        <AudioPlayerMobil />
      </MobileView>
    </>
  );
}

export default Player;
