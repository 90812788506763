import React from "react";
// import "./factura.css"; // CSS separat

const Factura = ({ plata }) => {
  const data = [
    {
      date: "2024-12-10",
      description: "Servicii de consultanță",
      period: "01/12/2024 - 10/12/2024",
      amount: 500,
      tvaPercentage: 19,
    },
    {
      date: "2024-12-05",
      description: "Proiectare web",
      period: "01/12/2024 - 05/12/2024",
      amount: 300,
      tvaPercentage: 19,
    },
  ];

  const calculateTVA = (amount, percentage) => (amount * percentage) / 100;
  const calculateTotal = (amount, tva) => amount + tva;

  return (
    <div className="invoice-container">
      <div className="invoice-header">
        <h1>Factura Fiscală</h1>
        <p>Data: {new Date().toLocaleDateString("ro-RO")}</p>
      </div>
      <table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Descriere</th>
            <th>Perioada de serviciu</th>
            <th>Sumă</th>
            <th>TVA %</th>
            <th>TVA</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            const tva = calculateTVA(item.amount, item.tvaPercentage);
            const total = calculateTotal(item.amount, tva);
            return (
              <tr key={index}>
                <td>{item.date}</td>
                <td>{item.description}</td>
                <td>{item.period}</td>
                <td>{item.amount.toFixed(2)} RON</td>
                <td>{item.tvaPercentage}%</td>
                <td>{tva.toFixed(2)} RON</td>
                <td>{total.toFixed(2)} RON</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Factura;
