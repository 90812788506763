import React, { useState, useEffect } from "react";

import useAuth from "../../hooks/useAuth";
import { Modal, Button, Input, Select } from "antd";
import { apiUrl } from "../../app/url";
import axios from "axios";
import "./Abonamente.css";
import { useSelector } from "react-redux";

function Subscribe({ productAb, open, setOpen }) {
  const { id, email } = useAuth();
  const token = useSelector((state) => state.auth.token);

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  // const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({
    orderId: generateOrderNumber(),
    categorieId: productAb._id,
    pret: productAb.pret,
    luniAbonamnet: 1,
    email: email,
    numeCategorie: productAb.categorie,
  });
  const [errorTelefon, setErrorTelefon] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validatePhoneNumber = (value) => {
    const phoneRegex = /^[0-9]{10}$/; // Validează un număr de telefon cu 10 cifre
    if (!value) {
      return "Numărul de telefon este obligatoriu.";
    }
    if (!phoneRegex.test(value)) {
      return "Te rugăm să introduci un număr de telefon valid.";
    }
    return ""; // Fără erori
  };

  useEffect(() => {
    setProduct({
      orderId: generateOrderNumber(),
      categorieId: productAb._id,
      pret: productAb.pret,
      luniAbonamnet: 1,
      email: email,
      numeCategorie: productAb.categorie,
    });
  }, [productAb]);

  const handleCheckout = async () => {
    const telefonValid = validatePhoneNumber(product.telefon);
    if (telefonValid) {
      setErrorTelefon(telefonValid);
      return;
    }

    setIsSubmitting(true);

    axios
      .post(
        `${apiUrl}/plati/plata`,
        {
          product,
          userId: id,
        },
        config
      )
      .then((response) => {
        if (response.data.paymentUrl) {
          const encryptedXml = response.data.encryptedXml;

          const form = document.createElement("form");
          form.action = response.data.paymentUrl;
          form.method = "POST";
          form.innerHTML = `
            <input type="hidden" name="env_key" value="${encryptedXml.envKey}">
            <input type="hidden" name="data" value="${encryptedXml.envData}">
            <input type="hidden" name="cipher" value="${encryptedXml.cipher}">
            <input type="hidden" name="iv" value="${encryptedXml.iv}">
            `;
          document.body.appendChild(form);
          form.submit();
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.error("Eroare la inițierea plății:", error);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        centered
        footer={false}
        styles={{
          content: {
            backgroundColor: "#18181B",
            color: "white",
            border: "1px solid #3F3F46",
          },
        }}
      >
        <div styles={{ borderRadius: "30px" }}>
          <div
            style={{
              marginBottom: "10px",
              fontSize: "1.2rem",
              display: "flex",
              gap: "0.7rem",
              flexDirection: "column",
            }}
          >
            <h2>Pachetul ales: {product.numeCategorie}</h2>

            <span>
              Perioada:{" "}
              <Select
                value={product.luniAbonamnet}
                style={{ width: 120 }}
                size="small"
                onChange={(e) => setProduct({ ...product, luniAbonamnet: e })}
                options={[
                  { value: 1, label: "o luna" },
                  { value: 5, label: "6 luni" },
                  { value: 10, label: "un an" },
                ]}
              />{" "}
              - {product.pret * product.luniAbonamnet} RON /{" "}
              {ofertaAbonament(product.luniAbonamnet)}
            </span>

            <Input
              value={product.numeFamilie}
              onChange={(e) =>
                setProduct({ ...product, numeFamilie: e.target.value })
              }
              placeholder="Numele de familie"
            />
            <Input
              value={product.prenume}
              onChange={(e) =>
                setProduct({ ...product, prenume: e.target.value })
              }
              placeholder="Prenumele"
            />
            <Input
              value={product.telefon}
              type="number"
              onChange={(e) => {
                setProduct({ ...product, telefon: e.target.value });
                setErrorTelefon("");
              }}
              placeholder="Numarul de telefon"
              style={errorTelefon ? { borderColor: "red" } : {}}
            />
            {errorTelefon && (
              <div style={{ color: "red", marginTop: 4 }}>{errorTelefon}</div>
            )}
            <div>Email: {email}</div>

            <div>
              Facturare!
              <p>
                Conform legislației din România, avem nevoie de județul și
                orașul cumpărătorului pentru a genera factura fiscală
              </p>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", gap: "0.7rem" }}
            >
              <Input
                value={product.judet}
                onChange={(e) =>
                  setProduct({ ...product, judet: e.target.value })
                }
                placeholder="Judetul"
              />
              <Input
                value={product.oras}
                onChange={(e) =>
                  setProduct({ ...product, oras: e.target.value })
                }
                placeholder="Orasul"
              />
            </div>
          </div>

          <Button onClick={() => handleCheckout()} disabled={isSubmitting}>
            {isSubmitting ? "Se procesează..." : "Plătește"}
          </Button>
          {isSubmitting && (
            <div className="loader">
              <p>
                Vă rugăm să așteptați. Redirecționăm către pagina de plată...
              </p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Subscribe;

const generateOrderNumber = () => {
  // Obține data curentă în format YYYYMMDD
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Adaugă zero dacă luna are o singură cifră
  const day = String(today.getDate()).padStart(2, "0");
  const datePart = `${year}${month}${day}`;

  // Generează un string aleatoriu de 8 caractere
  const randomPart = Math.random().toString(16).substr(2, 13);

  // Concatenează data și string-ul aleatoriu
  return `${datePart}-${randomPart}`;
};

const ofertaAbonament = (luni) => {
  switch (luni) {
    case 1:
      return " luna";
    case 5:
      return " 6 luni (o luna gratis)";
    case 10:
      return " 12 luni (doua luni gratis)";
    default:
      return " gratis";
  }
};
