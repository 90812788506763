import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "../player/card/Card";

function SongsList({ dateToStart }) {
  const songs = useSelector((state) => state.player.songs);

  const unlockSong = (song) => {
    const unlockTime = new Date(dateToStart);
    unlockTime.setDate(unlockTime.getDate() + song.unlockPeriod); // Aplica regula generală
    return unlockTime;
  };

  // const newListSongs = songs.filter((song) => new Date() >= unlockSong(song));

  // useEffect(() => {
  //   dispatch(songListUpdated(newListSongs));
  // }, []);
  // console.log(newListSongs);
  return (
    <>
      {songs[0].src !== "" && (
        <>
          <div
            style={{
              display: "flex",
              padding: "5px",
              justifyContent: "center",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            {songs?.map((item, i) => {
              return (
                <Card
                  key={i}
                  currentSong={item}
                  title={item.name}
                  artist={"abcdef"}
                  image={item.img}
                  unlockTime={unlockSong(item)}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default SongsList;
