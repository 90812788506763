import React, { useEffect, useState } from "react";
import { useGetUserQuery, useUpdateUserMutation } from "./usersApiSlice";
import PulseLoader from "react-spinners/PulseLoader";
import useAuth from "../../hooks/useAuth";
import { Button, Modal, Form, Input } from "antd";

const USER_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const PWD_REGEX = /^[A-z0-9!@#$%]{4,20}$/;

const UsersList = () => {
  const { id } = useAuth();

  const {
    data: user,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetUserQuery(id, {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [editModalVisibilty, setEditModalVisibilty] = useState(false);

  const [email, setUsername] = useState(user ? user.user.email : "");
  const [validUsername, setValidUsername] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  useEffect(() => {
    setValidUsername(USER_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);

  const onUsernameChanged = (e) => setUsername(e.target.value);
  const onPasswordChanged = (e) => setPassword(e.target.value);

  const [updateUser] = useUpdateUserMutation();

  let content;

  const getUser = () => {
    if (isLoading) content = <PulseLoader color={"#FFF"} />;

    if (isError) {
      content = <p className="errmsg">{error?.data?.message}</p>;
    }

    if (isSuccess) {
      setUsername(user.user.email);
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [user, isSuccess]);

  const onSaveUserClicked = async (e) => {
    if (password) {
      await updateUser({
        id: user.user._id,
        email,
        password,
      });
    } else {
      await updateUser({
        id: user.user._id,
        email,
      });
    }
  };

  let canSave;
  if (password) {
    canSave = [validUsername, validPassword].every(Boolean) && !isLoading;
  } else {
    canSave = [validUsername].every(Boolean) && !isLoading;
  }

  const validUserClass = !validUsername ? "form__input--incomplete" : "";
  const validPwdClass =
    password && !validPassword ? "form__input--incomplete" : "";

  if (isSuccess) {
    content = (
      <>
        <Button
          style={{ width: "90%", marginBottom: "1rem" }}
          onClick={() => setEditModalVisibilty(true)}
        >
          Editează profilul
        </Button>

        {editModalVisibilty && (
          <Modal
            onCancel={() => {
              setEditModalVisibilty(false);
            }}
            open={editModalVisibilty}
            centered
            title={`Modifica datele contului`}
            footer={false}
          >
            <Form>
              <div>Email:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={`${validUserClass}`}
                id="email"
                name="email"
                type="text"
                autoComplete="off"
                value={email}
                onChange={onUsernameChanged}
              />

              <div>
                Parola: ( parola ramane aceeasi daca nu completati nimic )
              </div>
              <Input.Password
                style={{ marginBottom: "10px" }}
                className={` ${validPwdClass}`}
                id="password"
                name="password"
                type="password"
                value={password}
                onChange={onPasswordChanged}
              />

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => {
                    onSaveUserClicked();
                    setEditModalVisibilty(false);
                  }}
                  disabled={!canSave}
                >
                  {`Salveaza modificarile`}
                </Button>
              </div>
            </Form>
          </Modal>
        )}
      </>
    );
  }

  return content;
};
export default UsersList;
