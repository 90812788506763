import React from "react";
import { Button } from "antd";
import { formatDate } from "../../hooks/date";
import card from "../../resourses/MASTERCARD.png";
import { useNavigate } from "react-router-dom";

function CardAbonament({ subs }) {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          width: "90%",
          marginBottom: "1rem",
          backgroundColor: "#d9d9d9",
          color: "black",
          borderRadius: "8px",
        }}
      >
        <h3 style={{ padding: "0.5rem" }}>Abonament {subs.categorieName}</h3>
        <div
          style={{
            marginBottom: "0.5rem",
          }}
        >
          <div
            style={{
              marginLeft: "0.5rem",
            }}
          >
            <div style={{ color: subs.isSubscribe ? "black" : "red" }}>
              {subs.isSubscribe
                ? `Urmatoarea plata: ${formatDate(subs.expireSub)}`
                : "Abonament expirat"}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                gap: "7px",
                alignItems: "center",
              }}
            >
              <img src={card} alt="card" /> <div>**** **** **** 1234</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                width: "95%",
                marginTop: "0.4rem",
              }}
            >
              Gestionarea metodei de plată
            </Button>
            <Button
              style={{
                width: "95%",
                marginBottom: "0.5rem",
                marginTop: "0.4rem",
              }}
              onClick={() => navigate(`/billingActivity/${subs.categoriId}`)}
            >
              Vizualizează istoricul de plată
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CardAbonament;
