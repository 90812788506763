import React, { useContext } from "react";
import "./Card.css"; // Importăm stilurile
import { AudioPlayerContext } from "../../../components/DefaultLayout";
import { useDispatch } from "react-redux";
import { playOrPause, changeSong } from "../actions";

const Card = ({ title, artist, image, currentSong, unlockTime }) => {
  const dispatch = useDispatch();
  const { audioPlayer } = useContext(AudioPlayerContext);

  async function changeCurrentSong(song) {
    dispatch(changeSong(song));
    dispatch(playOrPause(true));
    await audioPlayer.current.pause();
    await audioPlayer.current.load();
    audioPlayer.current.play();
  }

  const calculateTimeUntilUnlock = (unlockTime) => {
    const now = new Date();
    const unlockDate = new Date(unlockTime);

    // if (unlockDate <= now) {
    //   return "Deblocat";
    // }

    const diff = unlockDate - now; // Diferența în milisecunde

    const days = Math.floor(diff / (1000 * 60 * 60 * 24)); // Conversie în zile
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // Ore
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)); // Minute
    const seconds = Math.floor((diff % (1000 * 60)) / 1000); // Secunde

    // Construim un mesaj lizibil
    return `Deblocare in: ${days > 0 ? days + " zile si " : ""}${String(
      hours
    ).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;
  };

  const isUnlocked = new Date() >= unlockTime;

  return (
    <div
      className="spotify-card"
      style={{
        // cursor: isUnlocked ? "pointer" : "not-allowed",
        opacity: isUnlocked ? 1 : 0.4,
        userSelect: "none",
      }}
      onClick={() => {
        if (isUnlocked) {
          changeCurrentSong(currentSong);
        }
      }}
    >
      <div className="album-cover">
        <img
          style={{ width: 120, height: 120 }}
          src={image}
          alt={`${title} Album Cover`}
        />
      </div>
      <div className="song-info">
        <h3 className="song-title">{title}</h3>
        <p className="artist-name">{artist}</p>
      </div>

      {!isUnlocked && <div>{calculateTimeUntilUnlock(unlockTime)}</div>}
    </div>
  );
};

export default Card;
