import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useGetAllPlatiQuery } from "../plati/subscribeApiSlice";
import PulseLoader from "react-spinners/PulseLoader";
import ListaFacturi from "./ListaFacturi";
import ListaFacturiMobil from "./ListaFacturiMobil";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function ActivitatePlati() {
  const { id } = useAuth();
  const { idCat } = useParams();

  const navigate = useNavigate();

  const [platiList, setPlatiList] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  let content;

  const {
    data: plati,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllPlatiQuery({ id, idCat });

  const getPlati = () => {
    if (isSuccess) {
      setPlatiList(plati);
    }
  };

  useEffect(() => {
    getPlati();
    // eslint-disable-next-line
  }, [plati, isSuccess]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (isLoading)
    content = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <PulseLoader color={"#FFF"} />
        </div>
      </>
    );

  if (isError) {
    content = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
            color: "red",
          }}
        >
          <h3>{error?.data?.message}</h3>
        </div>
      </>
    );
  }

  if (isSuccess) {
    content = (
      <>
        <div>
          <ArrowLeftOutlined
            onClick={() => navigate(`/user`)}
            style={{
              transform: "scale(1.5)",
              marginBottom: "1rem",
            }}
          />
        </div>
        <h1>Istoricul plăților</h1>
        Abonament {platiList[0]?.categorieName}
        {width < 950 ? (
          <ListaFacturiMobil plati={platiList} />
        ) : (
          <ListaFacturi plati={platiList} />
        )}
      </>
    );
  }

  return content;
}

export default ActivitatePlati;
