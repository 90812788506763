import React, { useState } from "react";
import { dataTime } from "../../hooks/date";
import card from "../../resourses/MASTERCARD.png";
import { ContainerOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import Factura from "./Factura";

function isEven(value) {
  if (value % 2 == 0) return true;
  else return false;
}

function ListaFacturiMobil({ plati }) {
  const [printVisibility, setPrintVisibilty] = useState(false);
  return (
    <>
      <div style={{ marginBottom: "0.5rem", marginTop: "1.2rem" }}>
        Istoricul plăților
      </div>
      {plati.map((item, i) => {
        const tva = 1.19;
        const priceWithoutTva = (item.pret / tva).toFixed(2);
        return (
          <div
            key={i}
            style={{
              backgroundColor: isEven(i) ? "#363535" : "",
              padding: "0.5rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: "16px",
                padding: "0.1rem",
              }}
            >
              <div>{dataTime(item.subscribeData)}</div>
              <h3 style={{}}>{item.pret} LEI</h3>
            </div>
            <div style={{ fontSize: "12px", marginBottom: "0.3rem" }}>
              <div>
                {priceWithoutTva} LEI (+
                {(item.pret - priceWithoutTva).toFixed(2)} LEI TVA)
              </div>
              <div>{item.descriere}</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={card}
                  alt="card"
                  style={{ transform: "scale(0.7)" }}
                />
                <div>**** **** **** 1234</div>
              </div>
            </div>
            <div
              style={{ cursor: "pointer", width: "120px" }}
              onClick={() => {
                setPrintVisibilty(item);
              }}
            >
              <ContainerOutlined /> Afișare factură
            </div>
          </div>
        );
      })}

      <div style={{ marginTop: "0.5rem" }}>
        Se afișează istoricul de facturare pentru o perioadă de până la un an
      </div>

      <Modal
        onCancel={() => {
          setPrintVisibilty(false);
        }}
        open={printVisibility}
        footer={false}
        width={840}
      >
        <Factura plata={printVisibility} />
      </Modal>
    </>
  );
}

export default ListaFacturiMobil;
