import axios from "axios";
import { apiUrl } from "../../app/url";

export const getPlata = async (id, config) => {
  try {
    const { data } = await axios.get(apiUrl + `/plati/${id}`, config);
    return {
      data: data,
      mesaj: "Extragerea datelor finalizata!",
    };
  } catch (error) {
    return { data: false, mesaj: error.response.data.message };
  }
};
