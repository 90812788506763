import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const songsAdapter = createEntityAdapter({});

const initialState = songsAdapter.getInitialState();

export const songsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSong: builder.query({
      query: (id) => ({
        url: `/hipnoze/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      // transformResponse: (responseData) => {
      //   responseData.song.id = responseData.song._id
      //   return songsAdapter.setAll(initialState, responseData);
      // },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Song", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Song", id })),
          ];
        } else return [{ type: "Song", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetSongQuery } = songsApiSlice;

// returns the query result object
export const selectSongResult = songsApiSlice.endpoints.getSong.select();

// creates memoized selector
const selectSongsData = createSelector(
  selectSongResult,
  (songsResult) => songsResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllSongs,
  selectById: selectSongById,
  selectIds: selectSongIds,
  // Pass in a selector that returns the songs slice of state
} = songsAdapter.getSelectors(
  (state) => selectSongsData(state) ?? initialState
);
