import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const subscribeAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = subscribeAdapter.getInitialState();

export const subscribeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSubscribe: builder.query({
      query: ({ id, catId }) => ({
        url: `/plati/isubscribe/${id}/${catId}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Subscribe", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Subscribe", id })),
          ];
        } else return [{ type: "Subscribe", id: "LIST" }];
      },
    }),

    getAllPlati: builder.query({
      query: ({ id, idCat }) => ({
        url: `/plati/getallplati/${id}/${idCat}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Subscribes", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Subscribes", id })),
          ];
        } else return [{ type: "Subscribes", id: "LIST" }];
      },
    }),

    getBatchSubscribe: builder.query({
      query: (initialSubsData) => ({
        url: `/plati/isubscribe`,
        method: "POST", // Folosim POST pentru a trimite un array
        body: {
          ...initialSubsData,
        }, // Trimiterea array-ului în corpul cererii
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      // transformResponse: (responseData) => {
      //   console.log(responseData);
      //   // const loadedSubscribe = responseData.map((subscribe) => {
      //   //   subscribe.id = subscribe._id;
      //   //   return subscribe;
      //   // });
      //   // return subscribeAdapter.setAll(initialState, loadedSubscribe);
      // },
      providesTags: (result, error, arg) => {
        if (result?.length) {
          return [
            { type: "Subscribe", id: "LIST" },
            ...result.map((item) => ({ type: "Subscribe", id: item.itemId })),
          ];
        } else {
          return [{ type: "Subscribe", id: "LIST" }];
        }
      },
    }),

    getSubs: builder.mutation({
      query: (initialSubsData) => ({
        url: "/plati/isubscribe",
        method: "POST",
        body: {
          ...initialSubsData,
        },
      }),
      invalidatesTags: [{ type: "Subscribe", id: "LIST" }],
    }),
  }),
});

export const {
  useGetSubscribeQuery,
  useGetAllPlatiQuery,
  useGetBatchSubscribeQuery,
  useGetSubsMutation,
} = subscribeApiSlice;

// returns the query result object
export const selectSubscribeResult =
  subscribeApiSlice.endpoints.getSubscribe.select();

// creates memoized selector
const selectSubscribeData = createSelector(
  selectSubscribeResult,
  (subscribeResult) => subscribeResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllSubscribe,
  selectById: selectSubscribeById,
  selectIds: selectSubscribeIds,
  // Pass in a selector that returns the subscribe slice of state
} = subscribeAdapter.getSelectors(
  (state) => selectSubscribeData(state) ?? initialState
);
