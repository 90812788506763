import { store } from "../../app/store";
import { usersApiSlice } from "../user/usersApiSlice";
import { songsApiSlice } from "../Songs/songsApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const Prefetch = () => {
  const { id } = useAuth();

  useEffect(() => {
    store.dispatch(
      usersApiSlice.util.prefetch("getUser", id, {
        force: true,
      })
    );
  }, [id]);

  useEffect(() => {
    store.dispatch(
      songsApiSlice.util.prefetch("getSong", id, {
        force: true,
      })
    );
  }, [id]);

  return <Outlet />;
};
export default Prefetch;
