import { Outlet, Navigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useRefreshMutation } from "./authApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";
import PulseLoader from "react-spinners/PulseLoader";

const PersistLogin = () => {
  const token = useSelector(selectCurrentToken);
  const effectRan = useRef(false);

  const [trueSuccess, setTrueSuccess] = useState(false);

  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      // React 18 Strict Mode
      const verifyRefreshToken = async () => {
        console.log("verifying refresh token");
        try {
          //const response =
          await refresh();
          // const { accessToken } = response.data;
          setTrueSuccess(true);
        } catch (err) {
          console.error(err);
        }
      };
      if (!token) verifyRefreshToken();
    }

    return () => (effectRan.current = true);
  }, [token, refresh]);

  let content;

  if (isLoading) {
    //token: no
    console.log("loading");
    content = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#0f172a",
          minWidth: "100%",
          height: "100vh",
        }}
      >
        <PulseLoader color={"white"} size={"1.5rem"} />
      </div>
    );
  } else if (isError) {
    //token: no
    console.log("error");
    content = (
      <p className="errmsg">
        {`${error?.data?.message} - `}
        <Navigate to="/login">Please login again</Navigate>.
      </p>
    );
  } else if (isSuccess && trueSuccess) {
    //token: yes
    console.log("success");
    content = <Outlet />;
  } else if (token && isUninitialized) {
    //token: yes
    console.log("token and uninit");
    console.log(isUninitialized);
    content = <Outlet />;
  }

  return content;
};
export default PersistLogin;
