import React from "react";
import { Link } from "react-router-dom";

function Page404() {
  return (
    <div>
      <div>
        <p>Pagina pe care ai încercat să o accesezi nu există.</p>
        <strong>404 :</strong>
        <p>
          <Link to="/">Click aici pentru a te intorce pe site</Link>
        </p>
      </div>
    </div>
  );
}

export default Page404;
