// Functia primeste formatul de data de la The Guardian si generaza formatul dorit de noi.
export function getFormattedDate(dateString) {
  const currentDate = new Date(dateString);
  let month = currentDate.getMonth();
  month = month + 1;
  let day = currentDate.getDate();
  let year = currentDate.getFullYear();
  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return `${day}/${month}/${year}`;
}

export function formatDate(isoDate) {
  // Parsează data
  const date = new Date(isoDate);

  // Array pentru lunile anului
  const months = [
    "ianuarie",
    "februarie",
    "martie",
    "aprilie",
    "mai",
    "iunie",
    "iulie",
    "august",
    "septembrie",
    "octombrie",
    "noiembrie",
    "decembrie",
  ];

  // Extrage componentele datei
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Returnează data formatată
  return `${day} ${month} ${year}`;
}

export function formatReadableDate(isoDate) {
  // Parsează data
  const date = new Date(isoDate);

  // Array pentru lunile anului
  const months = [
    "ianuarie",
    "februarie",
    "martie",
    "aprilie",
    "mai",
    "iunie",
    "iulie",
    "august",
    "septembrie",
    "octombrie",
    "noiembrie",
    "decembrie",
  ];

  // Extrage componentele datei
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  // Extrage ora și minutele (opțional: ajustează fusul orar, dacă este necesar)
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0"); // Zero-padding

  // Returnează data formatată
  return `${day} ${month} ${year}, Ora: ${hours}:${minutes}`;
}

export function dataTime(date) {
  const newDate = new Date(date); // Copiem data pentru a nu modifica obiectul inițial

  // Formatăm data ca YYYY-MM-DD
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Luna începe de la 0, deci adăugăm 1
  const day = String(newDate.getDate()).padStart(2, "0");

  return `${day}.${month}.${year}`; // Returnăm data formatată
}
