import React, { useState } from "react";
import { useGetCatalogQuery } from "../catalog/catalogApiSlice";
import CheckIsSubscribe from "./CheckIsSubscribe";

function SongsAccess() {
  const { data: catalog, isLoading, isSuccess, error } = useGetCatalogQuery();
  const [selectedCategory, setSelectedCategory] = useState(null);

  return (
    <>
      <h1>Categorii: </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          flexDirection: "column",
          marginTop: "1.5rem",
        }}
      >
        <div>
          {isSuccess ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "5px",
                  gap: "20px",
                  flexWrap: "wrap",
                }}
              >
                {catalog?.map((item, i) => {
                  if (item.hipnoze > 0) {
                    return (
                      <div key={i}>
                        <div
                          style={{
                            color: "black",
                            padding: "10px",
                            border: "1px solid black",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backgroundColor:
                              selectedCategory?.categorie === item.categorie
                                ? "#b0b0b0"
                                : "#ffffff",
                          }}
                          onClick={() => setSelectedCategory(item)}
                        >
                          {item.categorie}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </>
          ) : isLoading ? (
            <p>Se incarca date...</p>
          ) : (
            <p>{error?.data.message}</p>
          )}
        </div>

        {selectedCategory && <CheckIsSubscribe cateorie={selectedCategory} />}
      </div>
    </>
  );
}

export default SongsAccess;
