import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input } from "antd";
import { useAddNewChestionarMutation } from "../chestionar/chestionarApiSlice";

function FirstLogin({ email, id, active }) {
  const [editModalVisibilty, setEditModalVisibilty] = useState(true);

  const [addNewChestionar, { isLoading, isSuccess }] =
    useAddNewChestionarMutation();

  const [companyName, setCompanyName] = useState();
  const [validCompanyName, setValidCompanyName] = useState(false);
  const [address, setAddress] = useState();
  const [validAddress, setValidAddress] = useState(false);
  const [cif, setCif] = useState();
  const [validCif, setValidCif] = useState(false);
  const [regCom, setRegCom] = useState();
  const [validRegCom, setValidRegCom] = useState(false);

  const [prelucrareDate, setPrelucrareDate] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      // inchidem fereastra
      setEditModalVisibilty(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    setValidCompanyName(companyName);
  }, [companyName]);

  useEffect(() => {
    setValidAddress(address);
  }, [address]);

  useEffect(() => {
    setValidCif(cif);
  }, [cif]);

  useEffect(() => {
    setValidRegCom(regCom);
  }, [regCom]);

  const onCompanyNameChanged = (e) => setCompanyName(e.target.value);
  const onAddressChanged = (e) => setAddress(e.target.value);
  const onCifChanged = (e) => setCif(e.target.value.toUpperCase());
  const onRegComChanged = (e) => setRegCom(e.target.value);
  const handleToggle = () => setPrelucrareDate((prev) => !prev);

  const onSaveUserClicked = async (e) => {
    await addNewChestionar({
      user: id,
      chestionarArr: {
        intrebarea1: cif,
        intrebarea2: regCom,
        intrebarea3: address,
        intrebarea4: companyName,
      },
    });
  };

  const canSave =
    [validCompanyName, validAddress, validCif, validRegCom].every(Boolean) &&
    !isLoading;

  const validCompanyNameClass = !validCompanyName
    ? "form__input--incomplete"
    : "";
  const validAddressClass = !validAddress ? "form__input--incomplete" : "";
  const validCifClass = !validCif ? "form__input--incomplete" : "";
  const validRegComClass = !validRegCom ? "form__input--incomplete" : "";

  return (
    <>
      {editModalVisibilty && (
        <Modal
          onCancel={() => {
            setEditModalVisibilty(false);
          }}
          open={editModalVisibilty}
          centered={true}
          closable={false}
          title={`Bine ai venit ${email}`}
          footer={false}
          maskClosable={false}
          keyboard={false}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ marginBottom: "10px" }}>
              Primul pas este sa completezi acest chestionar de mai jos
            </div>
            <Form>
              <div>Intrebarea 1:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={` ${validCifClass}`}
                id="cif"
                name="cif"
                type="text"
                autoComplete="off"
                value={cif}
                onChange={onCifChanged}
              />
              <div>Intrebarea 2:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={`${validCompanyNameClass}`}
                id="companyName"
                name="companyName"
                type="text"
                autoComplete="off"
                value={companyName}
                onChange={onCompanyNameChanged}
              />
              <div>Intrebarea 3:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={` ${validAddressClass}`}
                id="address"
                name="address"
                type="text"
                autoComplete="off"
                value={address}
                onChange={onAddressChanged}
              />
              <div>Intrebarea 4:</div>
              <Input
                style={{ marginBottom: "10px" }}
                className={`${validRegComClass}`}
                id="regCom"
                name="regCom"
                type="text"
                autoComplete="off"
                value={regCom}
                onChange={onRegComChanged}
              />
              <input
                type="checkbox"
                className="form__checkbox"
                id="persist"
                onChange={handleToggle}
                checked={prelucrareDate}
              />
              &nbsp;Sunt de acord cu prelucrarea datelor
              <div className="d-flex justify-content-end">
                <Button
                  type="primary"
                  onClick={() => {
                    onSaveUserClicked();
                  }}
                  disabled={!canSave}
                >
                  {`Salveaza datele`}
                </Button>
              </div>
            </Form>
          </div>
        </Modal>
      )}
    </>
  );
}

export default FirstLogin;
