import React, { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import Subscribe from "../plati/Subscribe";
import Card from "../catalog/card/Card";
import { apiUrl } from "../../app/url";
import { useGetSubscribeQuery } from "../plati/subscribeApiSlice";
import CheckWhenStart from "./CheckWhenStart";
import { dataTime } from "../../hooks/date";

function CheckIsSubscribe({ cateorie }) {
  const { id } = useAuth();
  const catId = cateorie._id;
  const [isSubscribe, setIsSubscribe] = useState([]);
  const [open, setOpen] = useState(false);

  const {
    data: subscribe,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSubscribeQuery({ id, catId });

  const getSub = async () => {
    if (isSuccess) {
      setIsSubscribe(subscribe);
    } else {
      setIsSubscribe(false);
    }
  };

  useEffect(() => {
    getSub();
  }, [isSuccess, cateorie]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      {isSubscribe.isSubscribe ? (
        <>
          <CheckWhenStart category={cateorie} />
        </>
      ) : (
        <>
          <div>Nu esti abonat la categoria {cateorie.categorie}</div>
          <div>Click mai jos pentru a te abona</div>
          {isSubscribe.expireSub && (
            <div style={{ color: "red" }}>
              Abonament expirat la data de {dataTime(isSubscribe.expireSub)}
            </div>
          )}

          <div onClick={() => setOpen(true)}>
            <Card catalog={cateorie} apiUrl={apiUrl} />
          </div>
        </>
      )}

      <Subscribe productAb={cateorie} open={open} setOpen={setOpen} />
    </div>
  );
}

export default CheckIsSubscribe;
