import React from "react";
import "./App.css";
import DefaultLayout from "./components/DefaultLayout";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./pages/Login";
import Register from "./features/auth/Register";
import Homepage from "./features/auth/Homepage";
import Setari from "./pages/Setari";
import Prefetch from "./features/auth/Prefetch";
import PersistLogin from "./features/auth/PersistLogin";
import RequireAuth from "./features/auth/RequireAuth";
import useTitle from "./hooks/useTitle";
import Plati from "./pages/Plati";
import Confirm from "./pages/Confirm";
import ActivitatePlati from "./features/user/ActivitatePlati";
import Page404 from "./pages/Page404";

function App() {
  useTitle("dependent.ro");

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />

          {/* Protected Routes */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth />}>
              <Route element={<Prefetch />}>
                <Route path="/" element={<DefaultLayout />}>
                  <Route index element={<Homepage />} />

                  <Route path="user">
                    <Route index element={<Setari />} />
                  </Route>
                  <Route path="abonare">
                    <Route index element={<Plati />} />
                  </Route>
                  <Route path="confirm">
                    <Route index element={<Confirm />} />
                  </Route>
                  <Route path="billingActivity/:idCat">
                    <Route index element={<ActivitatePlati />} />
                  </Route>

                  <Route path="*" element={<Page404 />} />
                </Route>
              </Route>
            </Route>
          </Route>
          {/* End Protected Routes */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
