import { Outlet, useNavigate } from "react-router-dom";
import React, { createContext, useRef } from "react";
import { Layout } from "antd";
import { useSelector } from "react-redux";
import Player from "../pages/Player";
import DropdownMenu from "./DropdownMenu";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

const { Header, Footer, Content } = Layout;

export const AudioPlayerContext = createContext();

function DefaultLayout() {
  const songs = useSelector((state) => state.player.songs);

  const navigate = useNavigate();

  const audioPlayer = useRef();

  return (
    <AudioPlayerContext.Provider value={{ audioPlayer }}>
      <Layout
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: "100dvh",
          backgroundColor: "black",
        }}
      >
        <Header
          style={{
            paddingLeft: 20,
            color: "white",
            backgroundColor: "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => navigate("/")}
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "white",
              color: "black",
              cursor: "pointer",
            }}
          >
            Logo
          </div>
          <h1>Dependent.Ro</h1>
          <DropdownMenu />
        </Header>
        <Content
          style={{
            margin: "10px 1rem",
            padding: 0,
            minHeight: 280,
            background: "rgb(18, 18, 18)",
            borderRadius: "0.5rem",
            color: "white",
          }}
        >
          <SimpleBar
            style={{
              height: "100%",
              padding: 24,
            }}
          >
            <Outlet />
          </SimpleBar>
        </Content>

        {songs[0].src !== "" && (
          <Footer
            style={{
              margin: "5px 1rem",
              padding: 12,
              backgroundColor: "black",
              color: "white",
            }}
          >
            <Player />
          </Footer>
        )}
      </Layout>
    </AudioPlayerContext.Provider>
  );
}

export default DefaultLayout;
