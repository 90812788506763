import { createAction } from "@reduxjs/toolkit";

export const playOrPause = createAction("player/playOrPause", (val = null) => ({
  payload: val,
}));

export const nextSong = createAction("player/nextAudio", () => ({}));

export const prevSong = createAction("player/prevAudio", () => ({}));

export const changeSong = createAction("player/changeSong", (currentSong) => ({
  payload: currentSong,
}));

export const songListUpdated = createAction(
  "player/songListUpdated",
  (newSongList) => ({ payload: newSongList })
);
