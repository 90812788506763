import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const catalogAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = catalogAdapter.getInitialState();

export const catalogApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCatalog: builder.query({
      query: (id) => ({
        url: `/catalog`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      // transformResponse: (responseData) => {
      //   const loadedCatalog = responseData.map((catalog) => {
      //     catalog.id = catalog._id;
      //     return catalog;
      //   });
      //   return catalogAdapter.setAll(initialState, loadedCatalog);
      // },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Catalog", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Catalog", id })),
          ];
        } else return [{ type: "Catalog", id: "LIST" }];
      },
    }),
  }),
});

export const { useGetCatalogQuery } = catalogApiSlice;

// returns the query result object
export const selectCatalogResult =
  catalogApiSlice.endpoints.getCatalog.select();

// creates memoized selector
const selectCatalogData = createSelector(
  selectCatalogResult,
  (catalogResult) => catalogResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllCatalog,
  selectById: selectCatalogById,
  selectIds: selectCatalogIds,
  // Pass in a selector that returns the catalog slice of state
} = catalogAdapter.getSelectors(
  (state) => selectCatalogData(state) ?? initialState
);
